var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"z-index":10,"title":_vm.title,"visible":_vm.visible,"width":"50%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.closeModal}},[_c('ax-form',{ref:"form",attrs:{"formBuilder":_vm.formBuilder},scopedSlots:_vm._u([{key:"activityTime",fn:function({ record }){return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'activityTimeStart',
            {
              initialValue: _vm.moment(_vm.activityTimeStart, 'HH:mm:ss'),
              rules: record.rules,
            },
          ]),expression:"[\n            'activityTimeStart',\n            {\n              initialValue: moment(activityTimeStart, 'HH:mm:ss'),\n              rules: record.rules,\n            },\n          ]"}],staticStyle:{"width":"100px"},attrs:{"getPopupContainer":(triggerNode) => triggerNode.parentNode,"format":"HH:mm:ss"},on:{"change":(val, dateStrings) =>
              _vm.changeTime(val, dateStrings, 'activityTimeStart')}}),_vm._v("至 "),_c('a-time-picker',{staticStyle:{"width":"100px"},attrs:{"getPopupContainer":(triggerNode) => triggerNode.parentNode,"disabledHours":_vm.getDisabledHours,"disabledMinutes":_vm.getDisabledMinutes,"disabledSeconds":_vm.getDisabledSeconds,"defaultValue":_vm.moment(_vm.activityTimeEnd, 'HH:mm:ss'),"format":"HH:mm:ss"},on:{"change":(val, dateStrings) =>
              _vm.changeTime(val, dateStrings, 'activityTimeEnd')}})],1)}},{key:"volunteerName",fn:function({ record }){return _c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            record.model,
            {
              rules: record.rules,
            },
          ]),expression:"[\n            record.model,\n            {\n              rules: record.rules,\n            },\n          ]"}],attrs:{"disabled":"","placeholder":"请输入"}}),_c('ax-button',{staticStyle:{"margin-left":"2%"},on:{"click":_vm.choosePeople}},[_vm._v("选择人员")])],1)}},{key:"integralScore",fn:function({ record }){return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            record.model,
            {
              rules: record.rules,
            },
          ]),expression:"[\n            record.model,\n            {\n              rules: record.rules,\n            },\n          ]"}],attrs:{"min":0,"max":999}}),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._v(" 积分每小时"),_c('span',{staticStyle:{"font-size":"0.06rem","color":"#8e969e"}},[_vm._v("（每小时可转换积分）")])])],1)}},{key:"fileIds",fn:function({ record }){return _c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          record.model,
          {
            rules: record.rules,
          },
        ]),expression:"[\n          record.model,\n          {\n            rules: record.rules,\n          },\n        ]"}]},[_c('imageUpload',{ref:"img1"})],1)}}])}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"background-color":"#556bff"},attrs:{"type":"primary","loading":_vm.saveType},on:{"click":_vm.onSubmit}},[_vm._v("保 存")]),_c('el-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取 消")])],1)],1),_c('choose-volunteer',{ref:"choosePeople",attrs:{"selectedPeopleList":_vm.selectedPeopleList},on:{"choose":_vm.receiveVolunteerId}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }